import React, { useLayoutEffect, useRef } from "react";

import renderMathInElement from "../util/auto-render";

export default React.memo(function InlineLaTeX(props: { children: string }) {
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (ref.current) {
      renderMathInElement(ref.current);
    }
  });

  return <span ref={ref}>{props.children}</span>;
});
