import "./SiteMenu.scss";

import React, { ReactNode, useCallback, useEffect, useRef } from "react";

import { ReactComponent as LogoLockup } from "../assets/logo-lockup.svg";
import { UserState } from "../db";
import { TableOfContentsNode } from "../document";
import essayMetadata, { orderedEssayIDs } from "../essays/essayMetadata";
import { ProgressByEssay } from "../util/getProgressByEssay";
import getRelativeDisplayStringForTimestamp from "../util/getRelativeDisplayStringForTimestamp";
import unreachableCaseError from "../util/unreachableCaseError";
import Button from "./Button";
import EssayProgressIndicator from "./EssayProgressIndicator";
import usePrevious from "./hooks/usePrevious";
import {
  isScrollBehaviorPolyfillReady,
  useScrollBehaviorPolyfill,
} from "./hooks/useScrollBehaviorPolyfill";
import { useScrollDependentState } from "./hooks/useScrollDependentState";
import useSignInRedirect from "./hooks/useSignInRedirect";
import InlineLaTeX from "./InlineLaTeX";
import { NavAndTableOfContentsLocation } from "./Nav";
import RadialProgressIndicator from "./RadialProgressIndicator";
import { SpacedRepetitionSchedule } from "../spacedRepetition";

interface SiteMenuProps {
  userState: UserState;
  progressByEssay: ProgressByEssay | null;
  currentLocation: NavAndTableOfContentsLocation;
  schedule: SpacedRepetitionSchedule;

  essayProgressIndicatorRef?: React.Ref<HTMLDivElement>;
  isShowingEssayProgressIndicatorOverlay?: boolean;
}

function useHasScrolledPastHero() {
  return useScrollDependentState(useCallback(() => window.scrollY > 500, []));
}

export default React.memo(function SiteMenu(props: SiteMenuProps) {
  const {
    userState,
    progressByEssay,
    currentLocation,
    essayProgressIndicatorRef,
    isShowingEssayProgressIndicatorOverlay,
    schedule,
  } = props;
  const { onSignIn, RedirectToSignInIfNecessary } = useSignInRedirect(
    currentLocation,
  );

  const hasScrolledPastHero = useHasScrolledPastHero();

  return (
    <div
      className={`SiteMenu ${
        isShowingEssayProgressIndicatorOverlay ? "ShowingOverlay" : ""
      }`}
    >
      <RedirectToSignInIfNecessary />
      <a
        href="/"
        style={{
          visibility:
            currentLocation.locationType === "frontPage" ? "hidden" : undefined,
        }}
      >
        <LogoLockup className="Logo" />
      </a>
      <ul className="EssayList">
        {orderedEssayIDs.map(iteratedEssayID => (
          <EssayEntry
            key={iteratedEssayID}
            essayID={iteratedEssayID}
            userState={userState}
            progress={
              (progressByEssay && progressByEssay[iteratedEssayID]) || null
            }
            currentLocation={currentLocation}
            hasScrolledPastHero={hasScrolledPastHero}
            essayProgressIndicatorRef={essayProgressIndicatorRef}
            schedule={schedule}
          />
        ))}
      </ul>
      <div className="BottomMaterial">
        {userState === "anonymous" && (
          <Button variant="tertiary" size="small" onActivate={onSignIn}>
            Sign in
          </Button>
        )}
        <ReviewRow currentLocation={currentLocation} />
        <div className="PatreonSection">
          <Button
            size="small"
            variant="secondary"
            onActivate={() =>
              (document.location.href = "https://patreon.com/quantumcountry")
            }
          >
            Support us on Patreon
          </Button>
          <p className="PatreonExplanation">
            Our future projects are funded in part by readers like you.
          </p>
          <p className="SponsorThanks">
            Special thanks to our sponsor-level patrons,
            <br />
            <a href="https://twitter.com/hirodusk">Adam Wiggins</a>,{" "}
            <a href="https://asuth.com">Andrew Sutherland</a>,{" "}
            <a href="http://somethingdoneright.net/">Bert Muthalaly</a>,{" "}
            <a href="http://calv.info/">Calvin French-Owen</a>,{" "}
            <a href="https://www.linkedin.com/in/dwight-crow-73122621">
              Dwight Crow
            </a>
            , <a href="https://fnnch.com">fnnch</a>,{" "}
            <a href="https://jameshk.com">James Hill-Khurana</a>,{" "}
            <a href="https://lambdalabs.com">Lambda AI Hardware</a>,{" "}
            <a href="https://twitter.com/ludwig">Ludwig Petersson</a>,{" "}
            <a href="http://www.t-1ventures.com/">Mickey McManus</a>,{" "}
            <a href="https://mintter.com/">Mintter</a>,{" "}
            <a href="https://patrickcollison.com">Patrick Collison</a>, Paul
            Sutter, <a href="https://peterhartree.co.uk">Peter Hartree</a>,{" "}
            <a href="https://www.sanalabs.com">Sana Labs</a>,{" "}
            <a href="https://shripriya.com/">Shripriya Mahesh</a>,{" "}
            <a href="https://www.oreilly.com/tim/">Tim O'Reilly</a>.
          </p>
        </div>
      </div>
    </div>
  );
});

const EssayEntry = React.memo(function EssayEntry(props: {
  essayID: string;
  userState: UserState;
  progress: ProgressByEssay[keyof ProgressByEssay] | null;
  currentLocation: NavAndTableOfContentsLocation;
  hasScrolledPastHero: boolean;
  schedule: SpacedRepetitionSchedule;
  essayProgressIndicatorRef?: React.Ref<HTMLDivElement>;
}) {
  const {
    essayID,
    userState,
    progress,
    currentLocation,
    hasScrolledPastHero,
    essayProgressIndicatorRef,
    schedule,
  } = props;
  const { title, hidden } = essayMetadata[essayID];

  const isCurrentLocation =
    currentLocation.locationType === "essay" &&
    currentLocation.essayID === essayID;

  const isEssayLinkDisabled =
    (hidden &&
      !isCurrentLocation &&
      (!progress || progress.total.collectedCardCount === 0)) ||
    false;

  let isEssayLinkActive: boolean;
  let children: ReactNode;

  switch (currentLocation.locationType) {
    case "review":
    case "frontPage":
      isEssayLinkActive = false;
      children = null;
      break;
    case "essay":
      const { currentTableOfContentsPath, tableOfContents } = currentLocation;

      function tableOfContentsNodeIsActive(node: TableOfContentsNode) {
        return (
          (currentTableOfContentsPath &&
            currentTableOfContentsPath[currentTableOfContentsPath.length - 1]
              .id === node.id) ||
          false
        );
      }

      isEssayLinkActive =
        isCurrentLocation && !currentLocation.currentTableOfContentsPath;

      if (isCurrentLocation) {
        children = (
          <ul className="TableOfContents">
            {tableOfContents.map(section => {
              const sectionOrSubsectionIsActive =
                currentTableOfContentsPath &&
                currentTableOfContentsPath.map(n => n.id).includes(section.id);
              return (
                <TableOfContentsSectionNodeLink
                  section={section}
                  isActive={tableOfContentsNodeIsActive(section)}
                  key={section.id}
                >
                  {sectionOrSubsectionIsActive &&
                    section.subsections.length > 0 && (
                      <ul>
                        {section.subsections.map(subsection => (
                          <TableOfContentsSectionNodeLink
                            key={subsection.id}
                            section={subsection}
                            isActive={tableOfContentsNodeIsActive(subsection)}
                          />
                        ))}
                      </ul>
                    )}
                </TableOfContentsSectionNodeLink>
              );
            })}
          </ul>
        );
      } else {
        children = null;
      }
      break;
    default:
      throw unreachableCaseError(currentLocation);
  }

  return (
    <li
      className={`${
        isEssayLinkActive ? "Active" : isEssayLinkDisabled ? "Disabled" : ""
      } ${isCurrentLocation ? "Current" : ""}`}
    >
      {/* Client-side navigation is broken because of our rendering chicanery. TODO: fix and re-enable */}
      {/* <NavLink to={`/${essayID}`}> */}
      {isEssayLinkDisabled ? (
        <span>
          {title}
          <br />
          (coming soon)
        </span>
      ) : (
        <a href={isEssayLinkActive ? "#top" : `/${essayID}`}>{title}</a>
      )}
      {!isCurrentLocation &&
        progress &&
        (progress.total.collectedCardCount > 0 ||
          (isEssayLinkActive && userState === "registered")) && (
          <div className="Progress">
            <RadialProgressIndicator
              progressFraction={
                progress!.total.collectedCardCount /
                progress!.total.totalCardCount
              }
              displayValue={progress!.total.collectedCardCount}
            />
          </div>
        )}
      {isCurrentLocation && (
        <>
          <div
            className={`EssayProgress ${
              hasScrolledPastHero ? "" : "BeforeHero"
            }`}
            style={progress === null ? { opacity: 0.5 } : undefined}
          >
            <div className="EssayIndicatorFlowFloat" />
            <EssayProgressIndicator
              form="exponential"
              progress={progress}
              ref={essayProgressIndicatorRef}
              schedule={schedule}
            />
          </div>
        </>
      )}
      {children}
    </li>
  );
});

const TableOfContentsSectionNodeLink = React.memo(
  function TableOfContentsSectionNodeLink(props: {
    section: TableOfContentsNode;
    isActive: boolean;
    children?: ReactNode;
  }) {
    const { isActive, section, children } = props;
    const wasActive = usePrevious(isActive);
    const elementRef = useRef<HTMLLIElement | null>(null);
    useScrollBehaviorPolyfill();

    useEffect(() => {
      if (
        elementRef.current &&
        !wasActive &&
        isActive &&
        isScrollBehaviorPolyfillReady()
      ) {
        let scrollableElement: HTMLElement | null =
          elementRef.current.parentElement;
        while (
          scrollableElement &&
          window.getComputedStyle(scrollableElement).overflow !== "scroll"
        ) {
          scrollableElement = scrollableElement.parentElement;
        }
        if (scrollableElement) {
          const boundingRect = elementRef.current.getBoundingClientRect();
          const scrollingBoundingRect = scrollableElement.getBoundingClientRect();
          const relativeY = boundingRect.top - scrollingBoundingRect.top;
          if (relativeY > 150) {
            scrollableElement.scrollTo({
              behavior: "smooth",
              top: scrollableElement.scrollTop + relativeY - 150,
              left: 0,
            });
          } else if (relativeY < 0) {
            scrollableElement.scrollTo({
              behavior: "smooth",
              top: scrollableElement.scrollTop + relativeY - 10,
              left: 0,
            });
          } else if (relativeY + scrollableElement.scrollTop < 170) {
            scrollableElement.scrollTo({
              behavior: "smooth",
              top: 0,
              left: 0,
            });
          }
        } else {
          console.error(
            "Couldn't find scrollable parent of ",
            elementRef.current,
          );
        }
      }
    }, [wasActive, isActive, section.title]);

    return (
      <li className={isActive ? "Active" : ""} ref={elementRef}>
        <a href={`#${section.id}`}>
          <InlineLaTeX>{section.title}</InlineLaTeX>
        </a>
        {children}
      </li>
    );
  },
);

const ReviewRow = React.memo(function ReviewRow(props: {
  currentLocation: NavAndTableOfContentsLocation;
}) {
  const { currentLocation } = props;
  let reviewStatusText;
  let isDisabled: boolean;
  let isActive: boolean;
  if (currentLocation.locationType === "review") {
    reviewStatusText = null;
    isDisabled = false;
    isActive = true;
  } else {
    const { approximateNextReviewSessionStatus } = currentLocation;
    if (!approximateNextReviewSessionStatus) {
      return null; // Still loading.
    }

    const isDue =
      approximateNextReviewSessionStatus.approximateTimestampMillis !== null &&
      approximateNextReviewSessionStatus.reviewSessionStatus.isScheduled &&
      Date.now() >=
        approximateNextReviewSessionStatus.approximateTimestampMillis;

    if (isDue) {
      const scheduledCardCount = (approximateNextReviewSessionStatus
        .reviewSessionStatus.isScheduled &&
        approximateNextReviewSessionStatus.reviewSessionStatus
          .scheduledCardCount)!;

      reviewStatusText = `${scheduledCardCount} question${
        scheduledCardCount! > 1 ? "s" : ""
      } ready for review`;
      isDisabled = false;
    } else {
      if (approximateNextReviewSessionStatus.approximateTimestampMillis) {
        let projectedDateText = getRelativeDisplayStringForTimestamp(
          approximateNextReviewSessionStatus.approximateTimestampMillis,
        );
        reviewStatusText = `next ready ${projectedDateText}`;
      } else {
        reviewStatusText = "collect more questions to unlock";
      }
      isDisabled = true;
    }
    isActive = false;
  }

  return (
    <div
      className={`ReviewRow ${
        isActive ? "Active" : isDisabled ? "Disabled" : ""
      }`}
    >
      {isDisabled ? (
        <span>Review questions</span>
      ) : (
        <a href="/review">Review questions</a>
      )}
      <span className="ReviewStatus">{reviewStatusText}</span>
    </div>
  );
});
