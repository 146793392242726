import moment from "moment";

export default function getRelativeDisplayStringForTimestamp(
  timestampMillis: number,
  timezoneOffset: number = new Date().getTimezoneOffset(),
) {
  let projectedDateText;
  const reviewMoment = moment(timestampMillis).utcOffset(-1 * timezoneOffset);
  if (reviewMoment.isBefore(moment().add(6, "days"))) {
    projectedDateText = `on ${reviewMoment.format("dddd")}`;
  } else {
    projectedDateText = reviewMoment.fromNow();
  }
  return projectedDateText;
}
