import { SpacedRepetitionSchedule } from "../spacedRepetition";
import { ExperimentCondition } from "./types";

export default function getEffectiveUserSchedule(
  condition: ExperimentCondition<"schedules202104"> | null,
): SpacedRepetitionSchedule {
  if (condition) {
    if (condition === "control") {
      // Here just for the type checker.
      throw new Error(
        "Condition for schedules202104 should never be able to be control.",
      );
    }
    return condition;
  } else {
    return "2021-04-A";
  }
}
