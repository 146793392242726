import "./Nav.scss";

import React, { useCallback, useMemo } from "react";

import { UserState } from "../db";
import {
  getFullPathForTableOfContentsNode,
  TableOfContents,
  TableOfContentsNode,
} from "../document";
import { EssayID } from "../essays/essayMetadata";
import { ApproximateNextReviewSessionStatus } from "../reviewSchedule";
import { ProgressByEssay } from "../util/getProgressByEssay";
import { useScrollDependentState } from "./hooks/useScrollDependentState";
import MobileNav from "./MobileNav";
import SiteMenu from "./SiteMenu";
import { StudySessionProgress } from "./StudySessionRemainingIndicator";
import useSizeClass from "./hooks/useSizeClass";
import { SpacedRepetitionSchedule } from "../spacedRepetition";

const isEqual = require("lodash.isequal");
export interface NavEssayLocation {
  locationType: "essay";
  essayID: EssayID;
  tableOfContents: TableOfContents;
  approximateNextReviewSessionStatus: ApproximateNextReviewSessionStatus | null;
  isActivelyReviewing: boolean;
}

export interface NavEssayAndTableOfContentsLocation extends NavEssayLocation {
  currentTableOfContentsPath: TableOfContentsNode[] | null;
}

export interface NavReviewLocation {
  locationType: "review";
  studySessionProgress: StudySessionProgress | null;
}

export interface NavFrontPageLocation {
  locationType: "frontPage";
  approximateNextReviewSessionStatus: ApproximateNextReviewSessionStatus | null;
}

export type NavLocation =
  | NavReviewLocation
  | NavEssayLocation
  | NavFrontPageLocation;

export type NavAndTableOfContentsLocation =
  | NavReviewLocation
  | NavFrontPageLocation
  | NavEssayAndTableOfContentsLocation;

interface NavProps {
  currentLocation: NavLocation;
  userState: UserState;
  progressByEssay: ProgressByEssay | null;

  essayProgressIndicatorRef?: React.Ref<HTMLDivElement>;
  isShowingEssayProgressIndicatorOverlay?: boolean;
  schedule: SpacedRepetitionSchedule;
}

export default function Nav(props: NavProps) {
  const {
    currentLocation,
    userState,
    progressByEssay,
    essayProgressIndicatorRef,
    isShowingEssayProgressIndicatorOverlay,
    schedule,
  } = props;

  const currentTableOfContentsPath = useCurrentTableOfContentsPath(
    (currentLocation.locationType === "essay" &&
      currentLocation.tableOfContents) ||
      null,
  );

  const augmentedCurrentLocation =
    currentLocation.locationType === "review"
      ? currentLocation
      : { ...currentLocation, currentTableOfContentsPath };

  const sizeClass = useSizeClass();

  return (
    <div className="NavContainer">
      <div className="DesktopSidebarContainer">
        <SiteMenu
          userState={userState}
          progressByEssay={props.progressByEssay}
          currentLocation={augmentedCurrentLocation}
          essayProgressIndicatorRef={
            sizeClass === "desktop" ? essayProgressIndicatorRef : null
          }
          isShowingEssayProgressIndicatorOverlay={
            isShowingEssayProgressIndicatorOverlay
          }
          schedule={schedule}
        />
      </div>
      <MobileNav
        userState={userState}
        progressByEssay={progressByEssay}
        currentLocation={augmentedCurrentLocation}
        essayProgressIndicatorRef={
          sizeClass === "handheld" ? essayProgressIndicatorRef ?? null : null
        }
        isShowingEssayProgressIndicatorOverlay={
          isShowingEssayProgressIndicatorOverlay || false
        }
        schedule={schedule}
      />
    </div>
  );
}

function useCurrentTableOfContentsPath(
  tableOfContents: TableOfContents | null,
): TableOfContentsNode[] | null {
  function getMapEntryForToCNode(
    node: TableOfContentsNode,
  ): [HTMLElement, TableOfContentsNode] {
    const element = document.getElementById(node.id)!;
    if (!element) {
      console.error(
        `Can't find "${node.id}" in the page. ToC will be broken for "${node.title}"`,
      );
    }
    return [element, node];
  }
  const DOMElementsToToCNodes = useMemo(
    () =>
      tableOfContents &&
      new Map(
        tableOfContents.flatMap(section => [
          getMapEntryForToCNode(section),
          ...section.subsections.map(getMapEntryForToCNode),
        ]) || null,
      ),
    [tableOfContents],
  );

  return useScrollDependentState(
    useCallback(
      (
        previousTableOfContentsPath: TableOfContentsNode[] | null | undefined,
      ) => {
        if (DOMElementsToToCNodes) {
          const scrollY = window.scrollY;
          let latestToCElement: HTMLElement | null = null;
          let latestToCNodeViewportTop: number | null = null;
          for (const element of DOMElementsToToCNodes.keys()) {
            // TODO memoize bounding rects until resize
            const boundingRect = element.getBoundingClientRect();
            const viewportTop = boundingRect.top + scrollY;
            if (
              viewportTop < scrollY + window.innerHeight / 2.0 &&
              (latestToCNodeViewportTop === null ||
                viewportTop >= latestToCNodeViewportTop)
            ) {
              latestToCElement = element;
              latestToCNodeViewportTop = viewportTop;
            }
          }
          const currentNode =
            latestToCElement && DOMElementsToToCNodes.get(latestToCElement)!;
          if (currentNode) {
            const newPath = getFullPathForTableOfContentsNode(
              currentNode,
              tableOfContents!,
            );
            if (
              previousTableOfContentsPath &&
              isEqual(previousTableOfContentsPath, newPath)
            ) {
              return previousTableOfContentsPath;
            } else {
              return newPath;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      },
      [DOMElementsToToCNodes, tableOfContents],
    ),
  );
}
