import { useEffect, useState } from "react";

export default function useViewportSize() {
  const [width, setWidth] = useState(document.body.clientWidth);
  const [height, setHeight] = useState(document.body.clientHeight);
  useEffect(() => {
    function onResize() {
      setWidth(document.body.clientWidth);
      setHeight(document.body.clientHeight);
    }
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return [width, height];
}
