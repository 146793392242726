import React, { useCallback, useEffect, useState } from "react";
import BlockField from "../components/BlockField";
import Nav from "../components/Nav";
import {
  fetchCardStates,
  fetchUserRecord,
  getAllCardData,
  subscribeToUserChanges,
  UserState,
} from "../db";
import {
  ApproximateNextReviewSessionStatus,
  getApproximateNextReviewSessionStatus,
} from "../reviewSchedule";
import getProgressByEssay, {
  ProgressByEssay,
} from "../util/getProgressByEssay";
import getPendingReviewSessionIndex from "../util/getPendingReviewSessionIndex";
import getEffectiveUserSchedule from "../experiments/getEffectiveUserSchedule";
import getEnrolledConditionForUser from "../experiments/getEnrolledConditionForUser";
import useViewportSize from "../components/hooks/useViewportSize";
import Button from "../components/Button";
import { ReactComponent as LogoLockup } from "../assets/logo-lockup-front-page.svg";

import "./FrontPageRoute.scss";
import { Helmet } from "react-helmet-async";
import { SpacedRepetitionSchedule } from "../spacedRepetition";

export default function FrontPageRoute() {
  const [userState, setUserState] = useState<UserState>("pending");
  const [
    progressByEssay,
    setProgressByEssay,
  ] = useState<ProgressByEssay | null>(null);
  const [
    approximateNextReviewSessionStatus,
    setApproximateNextReviewSessionStatus,
  ] = useState<ApproximateNextReviewSessionStatus | null>(null);
  const [schedule, setSchedule] = useState<SpacedRepetitionSchedule>("default");

  useEffect(() => {
    // sign in
    subscribeToUserChanges(async userState => {
      setUserState(userState);

      if (userState === "registered") {
        const [userRecord, cardStates, { cardIDsByEssay }] = await Promise.all([
          fetchUserRecord(),
          fetchCardStates(),
          getAllCardData(),
        ]);

        const newSchedule = getEffectiveUserSchedule(
          getEnrolledConditionForUser("schedules202104", userRecord),
        );
        setSchedule(newSchedule);
        setApproximateNextReviewSessionStatus(
          getApproximateNextReviewSessionStatus(
            getPendingReviewSessionIndex(userRecord),
            cardStates,
            newSchedule,
          ),
        );
        setProgressByEssay(
          getProgressByEssay(
            cardStates,
            cardIDsByEssay,
            newSchedule,
            userRecord.experimentConditions || {},
          ),
        );
      }
    });
  }, []);

  const viewportSize = useViewportSize(); // TODO avoid duplication with Hero; move into BlockField

  const onStartReading = useCallback(() => {
    window.location.href = "/qcvc"; // todo client-side nav
  }, []);

  return (
    <div className="FrontPage">
      <Helmet>
        <title>Quantum Country</title>
        <meta
          name="description"
          content="A free introduction to quantum computing and quantum mechanics, presented in a new mnemonic medium which makes it almost effortless to remember what you read."
        />
        <meta name="author" content="Andy Matuschak" />
        <meta name="author" content="Michael Nielsen" />
        <meta name="citation_title" content="Quantum Country" />
        <meta name="citation_author" content="Matuschak, Andy" />
        <meta name="citation_author" content="Nielsen, Michael" />
        <meta name="citation_publication_date" content="2019" />
        <meta
          name="citation_fulltext_html_url"
          content="https://quantum.country"
        />
        <meta name="citation_fulltext_world_readable" content="" />
        <meta property="og:url" content="https://quantum.country" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Quantum Country" />
        <meta
          property="og:description"
          content="A free introduction to quantum computing and quantum mechanics"
        />
        <meta
          property="og:image"
          content="https://quantum.country/home-social.png"
        />
        <meta property="og:image:alt" content="Quantum Country logo" />
        <meta property="og:site_name" content="Quantum Country" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1600" />
        <meta property="og:image:height" content="800" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Quantum Country" />
        <meta
          name="twitter:image"
          content="https://quantum.country/home-social.png"
        />
        <meta name="twitter:image:alt" content="Quantum Country logo" />
        <meta
          name="twitter:description"
          content="A free introduction to quantum computing and quantum mechanics"
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Quantum Country"
              }]
            }`}
        </script>
      </Helmet>
      <div className="BlockFieldContainer">
        <BlockField key={`${viewportSize[0]}-${viewportSize[1]}`} />
        <div className="FadeOverlay" />
      </div>
      <Nav
        currentLocation={{
          locationType: "frontPage",
          approximateNextReviewSessionStatus,
        }}
        userState={userState}
        progressByEssay={progressByEssay}
        schedule={schedule}
      />
      <div className="FrontPageContents">
        <div className="LogoLine">
          <LogoLockup />
          <div className="Byline">
            by <a href="https://andymatuschak.org">Andy Matuschak</a> and{" "}
            <a href="http://michaelnielsen.org">Michael Nielsen</a>
          </div>
        </div>
        <h1>A free introduction to quantum computing and quantum mechanics</h1>
        <p>
          By working through these essays, you will understand in detail all the
          basic principles of quantum computing and quantum mechanics, plus two
          important applications: the quantum search algorithm and quantum
          teleportation.
        </p>

        <div className="BottomRow">
          <p>
            You’ll need familiarity and comfort with the basics of linear
            algebra and complex numbers. We'll teach you the rest.
          </p>
          <Button variant="primary" size="regular" onActivate={onStartReading}>
            Start reading
          </Button>
        </div>

        <div className="MnemonicMedium">
          <h1>
            Presented in a new mnemonic medium which makes it almost effortless
            to remember what you read
          </h1>
          <p>
            Quantum Country is a new kind of book. Its interface integrates
            powerful ideas from cognitive science to make memory a choice. This
            is important in a topic like quantum computing, which overwhelms
            many learners with unfamiliar concepts and notation.
          </p>
        </div>
      </div>
    </div>
  );
}
