import { CardState, CardStates } from "./db";
import {
  projectedAverageIntervalAfterReview,
  SpacedRepetitionSchedule,
} from "./spacedRepetition";
import getCardLimitForReviewSession from "./util/getCardLimitForReviewSession";
import getDueCardIDs from "./util/getDueCardIDs";

// updated from query on 2019-11-07. query said 94.2%; I knocked it down a bit.
// https://console.cloud.google.com/bigquery?utm_source=bqui&utm_medium=link&utm_campaign=classic&project=metabook-qcc&j=bq:US:bquxjob_234c6a23_16e467c4496&page=queryresults
export const estimatedAccuracy = 0.9;
const minimumCardsDue = 12;

const oneDayIntervalMillis = 1000 * 60 * 60 * 24;
const getEstimatedCardsMissed = (
  dueCards: CardState[],
  currentTimeMillis: number,
) =>
  dueCards.reduce(
    (accumulator, card) =>
      accumulator +
      1 -
      Math.min(
        1,
        Math.pow(
          estimatedAccuracy,
          (currentTimeMillis - card.dueTime.toMillis()) /
            Math.max(card.interval, oneDayIntervalMillis),
        ),
      ),
    0,
  );

type ReviewSessionScheduledPolicy =
  | "first-session-due-threshold-exception"
  | "first-session-batching-exception"
  | "session-cap-reached"
  | "predicted-forgetting";

type ReviewSessionSkippedPolicy =
  | "insufficient-cards-due"
  | "cards-barely-due"
  | "insufficient-cards-collected";

type ReviewSessionStatus = {
  predictedCardsMissed: number;
  dueCardCount: number;
} & (
  | {
      policy: ReviewSessionScheduledPolicy;
      isScheduled: true;
      scheduledCardCount: number;
      projectedAverageIntervalAfterReview: number;
    }
  | { policy: ReviewSessionSkippedPolicy; isScheduled: false }
);

export function computeReviewSessionStatus(
  timestampMillis: number,
  pendingSessionIndex: number,
  cardStates: CardStates,
  schedule: SpacedRepetitionSchedule,
): ReviewSessionStatus {
  const dueCardIDs = getDueCardIDs({
    cardStates,
    cardsCompletedInCurrentSession: 0,
    reviewSessionIndex: pendingSessionIndex,
    timestampMillis,
  });
  const cardsDue = dueCardIDs.map(cardID => cardStates[cardID]);

  const predictedCardsMissed = getEstimatedCardsMissed(
    cardsDue,
    timestampMillis,
  );

  const hasEnoughCards = Object.keys(cardStates).length >= minimumCardsDue;
  const hasEnoughCardsDue = cardsDue.length >= minimumCardsDue;
  const isFirstSession = pendingSessionIndex === 0;

  const commonRecord = {
    predictedCardsMissed,
    dueCardCount: cardsDue.length,
  };

  const scheduledRecord = {
    isScheduled: true,
    scheduledCardCount: Math.min(
      cardsDue.length,
      getCardLimitForReviewSession(pendingSessionIndex),
    ),
    projectedAverageIntervalAfterReview: projectedAverageIntervalAfterReview(
      cardsDue.map(c => c.interval),
      schedule,
    ),
    ...commonRecord,
  } as const;

  const skippedRecord = {
    isScheduled: false,
    ...commonRecord,
  } as const;

  if (hasEnoughCardsDue) {
    if (isFirstSession) {
      // If you haven't studied before, and you have enough cards due, that's enough.
      return {
        policy: "first-session-batching-exception",
        ...scheduledRecord,
      };
    } else if (predictedCardsMissed >= 2) {
      // We predict you've forgotten enough cards to be worth studying.
      return {
        policy: "predicted-forgetting",
        ...scheduledRecord,
      };
    } else if (cardsDue.length > scheduledRecord.scheduledCardCount) {
      // No point in batching if you have a full session already.
      return {
        policy: "session-cap-reached",
        ...scheduledRecord,
      };
    } else {
      // You have enough cards due, but they're barely overdue, and we think you'll be better off if we batch your session.
      return {
        policy: "cards-barely-due",
        ...skippedRecord,
      };
    }
  } else if (
    isFirstSession &&
    schedule !== "aggressiveStart" &&
    cardsDue.length > 0
  ) {
    // Prior to the aggressiveStart schedule, we consider any card due to be enough.
    return {
      policy: "first-session-due-threshold-exception",
      ...scheduledRecord,
    };
  } else {
    if (hasEnoughCards) {
      return {
        policy: "insufficient-cards-due",
        ...skippedRecord,
      };
    } else {
      return {
        policy: "insufficient-cards-collected",
        ...skippedRecord,
      };
    }
  }
}

export interface ApproximateNextReviewSessionStatus {
  approximateTimestampMillis: number | null;
  reviewSessionStatus: ReviewSessionStatus;
}

export function getApproximateNextReviewSessionStatus(
  pendingSessionIndex: number,
  cardStates: CardStates,
  schedule: SpacedRepetitionSchedule,
): ApproximateNextReviewSessionStatus {
  for (
    let testTimestampMillis = Date.now();
    testTimestampMillis < Date.now() + 365 * oneDayIntervalMillis;
    testTimestampMillis += oneDayIntervalMillis
  ) {
    const reviewSessionStatus = computeReviewSessionStatus(
      testTimestampMillis,
      pendingSessionIndex,
      cardStates,
      schedule,
    );
    if (reviewSessionStatus.isScheduled) {
      return {
        approximateTimestampMillis: testTimestampMillis,
        reviewSessionStatus,
      };
    } else if (reviewSessionStatus.policy === "insufficient-cards-collected") {
      return { approximateTimestampMillis: null, reviewSessionStatus };
    }
  }
  throw new Error(
    `User appears to never have next review: ${JSON.stringify(
      cardStates,
    )}, but not because they don't have enough cards`,
  );
}
