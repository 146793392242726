import React, { useEffect, useRef, useState } from "react";
import useSizeClass, { SizeClass } from "./hooks/useSizeClass";
import seedrandom from "seedrandom";

const blockVertexShaderSource = `
precision mediump float;

attribute vec2 position;
attribute float sizeFactor;
attribute vec2 uv;
uniform float now;
uniform float devicePixelRatio;
uniform vec2 resolution;
uniform bool flip;

varying float v_sizeFactor;
varying vec2 v_uv;
 
void main() {

  float widthFactor = (resolution.x / devicePixelRatio) / 1024.0;
  float duration = 360.0 * widthFactor - sizeFactor * (300.0 * widthFactor);
  float offset = now / duration * (resolution.x + 540.0);
  float x = mod(position.x + offset, resolution.x + 270.0 * 2.0) - 270.0;

  // convert the position from pixels to 0.0 to 1.0
  vec2 zeroToOne = vec2(x, position.y) / resolution;

  // convert from 0->1 to 0->2
  vec2 zeroToTwo = zeroToOne * 2.0;

  // convert from 0->2 to -1->+1 (clip space)
  vec2 clipSpace = zeroToTwo - 1.0;

  float flippingFactor;
  if (flip) {
    flippingFactor = -1.0;
  } else {
    flippingFactor = 1.0;
  }

  gl_Position = vec4(clipSpace * vec2(1, flippingFactor), 0, 1);
  v_sizeFactor = sizeFactor;
  v_uv = uv;
}
`;
const blockFragmentShaderSource = `
precision mediump float;

varying float v_sizeFactor;
varying vec2 v_uv;
uniform vec2 resolution;
uniform bool flip;
 
void main() {
  float radius = 4.0 + v_sizeFactor * 16.0;
  float d = length(max(abs(v_uv - vec2(0.5, 0.5)), 0.4) - 0.4) - 0.07;

  // $key20 -> $key10
  float gradientPosition = gl_FragCoord.y / resolution.y;
  if (flip) {
    gradientPosition = 1.0 - gradientPosition;
  }
  vec3 color;
  if (gradientPosition < 0.66) {
    color = mix(vec3(0.854, 0.729, 1.0), vec3(0.961, 0.921, 1.0), gradientPosition / 0.66);
  } else {
    color = vec3(0.961, 0.921, 1.0);
  }
  gl_FragColor = vec4(color.xyz, 1.0 - step(0.50, d / 0.15 * 5.0));
}
`;
const fullScreenVertexShaderSource = `
precision mediump float;
attribute vec2 position;
uniform bool flip;

varying vec2 v_uv;

void main() {
  vec2 pos = position;
  v_uv = pos * 0.5 + 0.5;
  if (flip) {
    v_uv.y = 1.0 - v_uv.y;
  }
  gl_Position = vec4(pos, 0.0, 1.0);
}
`;
const blurFragmentShaderSource = `
precision mediump float;
uniform bool flip;
uniform sampler2D u_image;
uniform vec2 resolution;
uniform vec2 direction;
uniform float blurWidth;
uniform bool shouldApplyAlpha;
varying vec2 v_uv;

vec4 blur9(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
  vec4 color = vec4(0.0);
  vec2 off1 = vec2(1.3846153846) * direction;
  vec2 off2 = vec2(3.2307692308) * direction;
  color += texture2D(image, uv) * 0.2270270270;
  color += texture2D(image, uv + (off1 / resolution)) * 0.3162162162;
  color += texture2D(image, uv - (off1 / resolution)) * 0.3162162162;
  color += texture2D(image, uv + (off2 / resolution)) * 0.0702702703;
  color += texture2D(image, uv - (off2 / resolution)) * 0.0702702703;
  return color;
}

void main() {
  float navEdge = resolution.x - blurWidth - 1.0;
  if (gl_FragCoord.x >= navEdge) {
    gl_FragColor = blur9(u_image, v_uv, resolution, direction);
    if (shouldApplyAlpha) {
      // $key10 -> $key00
      float gradientPosition = gl_FragCoord.y / resolution.y;
      if (flip) {
        gradientPosition = 1.0 - gradientPosition;
      }

      // $key10 -> $key00 -> white
      vec3 gradientColor;
      if (gradientPosition < 0.4539) {
        gradientColor = mix(vec3(0.961, 0.921, 1.0), vec3(0.988, 0.980, 1.0), (gradientPosition / 0.4539));
      } else if (gradientPosition < 0.7923) {
        gradientColor = mix(vec3(0.988, 0.980, 1.0), vec3(1.0, 1.0, 1.0), (gradientPosition - 0.4539) / 0.3384);
      } else {
        gradientColor = vec3(1.0, 1.0, 1.0);
      }    
      gl_FragColor = gl_FragColor * gl_FragColor.a * 0.66 + vec4(gradientColor, 1.0) * (1.0 - gl_FragColor.a * 0.66 - 0.2) + vec4(0.2);
      
      // key line
      float borderD = step(navEdge + 1.0, gl_FragCoord.x);
      gl_FragColor = borderD * gl_FragColor + (1.0 - borderD) * vec4(gradientColor, 1.0);

    }
  } else {
    gl_FragColor = texture2D(u_image, v_uv);
  }
}
`;
const backgroundGradientFragmentShaderSource = `
precision mediump float;
 
varying vec2 v_uv;
uniform vec2 resolution;
uniform bool flip;

void main() {
  float gradientPosition = gl_FragCoord.y / resolution.y;
  if (flip) {
    gradientPosition = 1.0 - gradientPosition;
  }
  // $key10 -> $key00 -> white
  vec3 gradientColor;
  if (gradientPosition < 0.4539) {
    gradientColor = mix(vec3(0.961, 0.921, 1.0), vec3(0.988, 0.980, 1.0), (gradientPosition / 0.4539));
  } else if (gradientPosition < 0.7923) {
    gradientColor = mix(vec3(0.988, 0.980, 1.0), vec3(1.0, 1.0, 1.0), (gradientPosition - 0.4539) / 0.3384);
  } else {
    gradientColor = vec3(1.0, 1.0, 1.0);
  }
  gl_FragColor = vec4(gradientColor.xyz, 1.0);
}
`;

function createShader(gl: WebGLRenderingContext, type: number, source: string) {
  const shader = gl.createShader(type)!;
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
  if (success) {
    return shader;
  }

  console.log(gl.getShaderInfoLog(shader));
  gl.deleteShader(shader);
}

function createProgram(
  gl: WebGLRenderingContext,
  vertexShaderSource: string,
  fragmentShaderSource: string,
): WebGLProgram | null {
  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource)!;
  const fragmentShader = createShader(
    gl,
    gl.FRAGMENT_SHADER,
    fragmentShaderSource,
  )!;

  const program = gl.createProgram()!;
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
    gl.deleteProgram(program);
    return null;
  }
  return program;
}

interface Block {
  unitX: number;
  unitY: number;
  sizeFactor: number;
}

export default function BlockField() {
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const setPaused = useRef<((paused: boolean) => void) | null>(null);

  useEffect(() => {
    function updateVisibility() {
      setPaused.current &&
        setPaused.current(
          document.visibilityState && document.visibilityState !== "visible",
        );
    }

    document.addEventListener("visibilitychange", updateVisibility);
    return () =>
      document.removeEventListener("visibilitychange", updateVisibility);
  }, []);

  const sizeClass = useSizeClass();
  useEffect(() => {
    if (canvas) {
      resize(canvas); // note that this only works here because we trash the component when the size changes
      const gl = canvas.getContext("webgl", {
        alpha: sizeClass !== "desktop",
      })!;
      if (gl && !gl.isContextLost()) {
        setPaused.current = runGL(
          gl,
          sizeClass,
          canvas.clientWidth,
          canvas.clientHeight,
        );
        return () => {
          const extension = gl.getExtension("WEBGL_lose_context");
          extension && extension.loseContext();
          setPaused.current && setPaused.current(true);
        };
      }
    }
  }, [canvas, sizeClass]);

  function resize(canvas: HTMLCanvasElement) {
    const realToCSSPixels = window.devicePixelRatio;

    // Lookup the size the browser is displaying the canvas in CSS pixels
    // and compute a size needed to make our drawingbuffer match it in
    // device pixels.
    const displayWidth = Math.floor(canvas.clientWidth * realToCSSPixels);
    const displayHeight = Math.floor(canvas.clientHeight * realToCSSPixels);

    // Check if the canvas is not the same size.
    if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
      // Make the canvas the same size
      canvas.width = displayWidth;
      canvas.height = displayHeight;
    }
  }

  return <canvas ref={setCanvas} style={{ width: "100%", height: "100%" }} />;
}

function runGL(
  gl: WebGLRenderingContext,
  sizeClass: SizeClass,
  width: number,
  height: number,
): (isPaused: boolean) => void {
  const blockProgram = createProgram(
    gl,
    blockVertexShaderSource,
    blockFragmentShaderSource,
  )!;

  const nowUniformLocation = gl.getUniformLocation(blockProgram, "now")!;
  const devicePixelRatioUniformLocation = gl.getUniformLocation(
    blockProgram,
    "devicePixelRatio",
  )!;
  const flipBlockUniformLocation = gl.getUniformLocation(blockProgram, "flip")!;
  const resolutionBlockUniformLocation = gl.getUniformLocation(
    blockProgram,
    "resolution",
  )!;

  const positionBlockAttributeLocation = gl.getAttribLocation(
    blockProgram,
    "position",
  );
  const sizeFactorAttributeLocation = gl.getAttribLocation(
    blockProgram,
    "sizeFactor",
  );
  const uvAttributeLocation = gl.getAttribLocation(blockProgram, "uv");

  const backgroundGradientProgram = createProgram(
    gl,
    fullScreenVertexShaderSource,
    backgroundGradientFragmentShaderSource,
  )!;
  const resolutionGradientUniformLocation = gl.getUniformLocation(
    backgroundGradientProgram,
    "resolution",
  )!;
  const flipGradientUniformLocation = gl.getUniformLocation(
    backgroundGradientProgram,
    "flip",
  );
  const positionGradientAttributeLocation = gl.getAttribLocation(
    backgroundGradientProgram,
    "position",
  );

  const blurProgram = createProgram(
    gl,
    fullScreenVertexShaderSource,
    blurFragmentShaderSource,
  )!;
  const resolutionBlurUniformLocation = gl.getUniformLocation(
    blurProgram,
    "resolution",
  )!;
  const directionBlurUniform = gl.getUniformLocation(blurProgram, "direction")!;
  const flipBlurUniform = gl.getUniformLocation(blurProgram, "flip");
  const blurWidthBlurUniform = gl.getUniformLocation(blurProgram, "blurWidth");
  const shouldApplyAlphaBlurUniform = gl.getUniformLocation(
    blurProgram,
    "shouldApplyAlpha",
  );
  const positionBlurAttributeLocation = gl.getAttribLocation(
    blurProgram,
    "position",
  );

  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  const positions: number[] = [];

  const blocks = generateBlocks();

  function getSizeForBlock(blockIndex: number, size: SizeClass) {
    return (
      blocks[blockIndex].sizeFactor * (size === "handheld" ? 80 : 175) + 20
    );
  }

  const widthFactor = width / 1024;
  const heightFactor = height / 800;
  const blockCount = Math.max(35, Math.floor(42 * widthFactor * heightFactor));

  const desktopNavColumnWidth = 304; // HACK sync with layout.scss

  blocks.forEach((block, index) => {
    const size = getSizeForBlock(index, sizeClass) * window.devicePixelRatio;
    const x = block.unitX * (width + 270.0) * window.devicePixelRatio;
    const y =
      block.unitY *
      (height - size / 2.0 - (sizeClass === "desktop" ? 200 : 130)) *
      window.devicePixelRatio;
    const sizeFactor = block.sizeFactor;
    positions.push(
      x,
      y,
      sizeFactor,
      0,
      0,
      x + size,
      y,
      sizeFactor,
      1,
      0,
      x,
      y + size,
      sizeFactor,
      0,
      1,
      x,
      y + size,
      sizeFactor,
      0,
      1,
      x + size,
      y,
      sizeFactor,
      1,
      0,
      x + size,
      y + size,
      sizeFactor,
      1,
      1,
    );
  });

  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  const fullScreenRectBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, fullScreenRectBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([-1, 1, 1, 1, -1, -1, -1, -1, 1, 1, 1, -1]),
    gl.STATIC_DRAW,
  );

  gl.enable(gl.BLEND);
  gl.cullFace(sizeClass === "desktop" ? gl.BACK : gl.FRONT);

  // on mobile we draw straight to screen, so things are upside-down

  function createFrameBuffer() {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      width * window.devicePixelRatio,
      height * window.devicePixelRatio,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null,
    );
    const fbo = gl.createFramebuffer()!;
    gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      texture,
      0,
    );

    return [texture, fbo];
  }

  const [firstPassTexture, firstPassFBO] =
    sizeClass === "desktop" ? createFrameBuffer() : [null, null];
  const [secondPassTexture, secondPassFBO] =
    sizeClass === "desktop" ? createFrameBuffer() : [null, null];

  requestAnimationFrame(render);
  render(performance.now());

  function setFramebuffer(framebuffer: WebGLFramebuffer | null) {
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
  }

  let isPaused = false;

  function render(now: DOMHighResTimeStamp) {
    // First we render the blocks
    gl.useProgram(blockProgram);
    gl.enable(gl.CULL_FACE);
    setFramebuffer(sizeClass === "desktop" ? firstPassFBO : null); // render straight to screen on mobile, since we're not doing any blurring

    gl.blendFuncSeparate(
      gl.SRC_ALPHA,
      gl.ONE_MINUS_SRC_ALPHA,
      gl.ONE_MINUS_DST_ALPHA,
      gl.ONE_MINUS_SRC_ALPHA,
    );

    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.enableVertexAttribArray(positionBlockAttributeLocation);
    gl.enableVertexAttribArray(sizeFactorAttributeLocation);
    gl.enableVertexAttribArray(uvAttributeLocation);

    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

    const type = gl.FLOAT;
    const stride = 5 * 4;
    gl.vertexAttribPointer(
      positionBlockAttributeLocation,
      2,
      type,
      false,
      stride,
      0,
    );

    gl.vertexAttribPointer(
      sizeFactorAttributeLocation,
      1,
      type,
      false,
      stride,
      2 * 4,
    );

    gl.vertexAttribPointer(uvAttributeLocation, 2, type, false, stride, 3 * 4);

    gl.uniform1f(nowUniformLocation, now * 0.001);
    gl.uniform1f(devicePixelRatioUniformLocation, window.devicePixelRatio);
    gl.uniform1f(flipBlockUniformLocation, sizeClass === "desktop" ? 0 : 1);
    gl.uniform2f(
      resolutionBlockUniformLocation,
      gl.canvas.width,
      gl.canvas.height,
    );

    const primitiveType = gl.TRIANGLES;
    const count = Math.min(blockCount, blocks.length) * 6;
    gl.drawArrays(primitiveType, 0, count);

    // Draw the background gradient.
    gl.disable(gl.CULL_FACE);
    gl.blendFuncSeparate(
      gl.ONE_MINUS_DST_ALPHA,
      gl.DST_ALPHA,
      gl.ONE_MINUS_DST_ALPHA,
      gl.DST_ALPHA,
    );

    gl.useProgram(backgroundGradientProgram);
    gl.bindBuffer(gl.ARRAY_BUFFER, fullScreenRectBuffer);
    gl.enableVertexAttribArray(positionGradientAttributeLocation);
    gl.vertexAttribPointer(
      positionGradientAttributeLocation,
      2,
      type,
      false,
      0,
      0,
    );
    gl.uniform2f(
      resolutionGradientUniformLocation,
      gl.canvas.width,
      gl.canvas.height,
    );
    gl.uniform1f(flipGradientUniformLocation, sizeClass === "desktop" ? 0 : 1);

    gl.drawArrays(primitiveType, 0, 6);

    if (sizeClass === "desktop") {
      // Now draw through the blur program. We'll do two passes.
      gl.blendFuncSeparate(
        gl.SRC_ALPHA,
        gl.ONE_MINUS_SRC_ALPHA,
        gl.SRC_ALPHA,
        gl.ONE_MINUS_SRC_ALPHA,
      );
      gl.useProgram(blurProgram);
      gl.uniform1i(flipBlurUniform, 0);
      gl.uniform1i(shouldApplyAlphaBlurUniform, 0);
      gl.uniform1f(
        blurWidthBlurUniform,
        desktopNavColumnWidth * window.devicePixelRatio,
      );
      gl.uniform2f(
        resolutionBlurUniformLocation,
        gl.canvas.width,
        gl.canvas.height,
      );

      gl.enableVertexAttribArray(positionBlurAttributeLocation);
      gl.vertexAttribPointer(
        positionBlurAttributeLocation,
        2,
        type,
        false,
        0,
        0,
      );

      setFramebuffer(secondPassFBO);
      gl.bindTexture(gl.TEXTURE_2D, firstPassTexture);
      gl.uniform2f(directionBlurUniform, 2, 0);
      gl.drawArrays(primitiveType, 0, 6);

      setFramebuffer(firstPassFBO);
      gl.bindTexture(gl.TEXTURE_2D, secondPassTexture);
      gl.uniform2f(directionBlurUniform, 0, 2);
      gl.drawArrays(primitiveType, 0, 6);

      setFramebuffer(secondPassFBO);
      gl.bindTexture(gl.TEXTURE_2D, firstPassTexture);
      gl.uniform2f(directionBlurUniform, 1, 0);
      gl.drawArrays(primitiveType, 0, 6);

      setFramebuffer(null);
      gl.bindTexture(gl.TEXTURE_2D, secondPassTexture);
      gl.uniform2f(directionBlurUniform, 0, 1);
      gl.uniform1i(flipBlurUniform, 1);
      gl.uniform1i(shouldApplyAlphaBlurUniform, 1);
      gl.drawArrays(primitiveType, 0, 6);
    }

    if (!isPaused) {
      requestAnimationFrame(render);
    }
  }

  return (paused: boolean) => {
    if (isPaused && !paused) {
      requestAnimationFrame(render);
    }
    isPaused = paused;
  };
}

function generateBlocks() {
  const rng = seedrandom("blockField");
  const blockCount = 500;
  const output: Block[] = [];
  for (let blockIndex = 0; blockIndex < blockCount; blockIndex++) {
    const sizeFactor = Math.pow(1 - rng(), 3);
    const unitX = rng();
    const unitY = rng();
    output.push({
      unitX,
      unitY,
      sizeFactor,
    });
  }

  return output;
}
