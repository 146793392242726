import { UserRecord } from "../db";
import getEnrolledConditionForUser from "./getEnrolledConditionForUser";

export const efficacyExperimentCardIDSet: Set<string> = new Set([
  "h1AXHXVtsGKxkamS8Hb2",
  "6NRxe1wnphNsMjTZ9LXf",
  "xQte6vwqUwy2j5OWdxjK",
  "1ENYKL02RT8aidhI917F",
  "zRgw2osbesLEQ5ADo8DD",
  "E4g8wyr2folQ87Slf3ZO",
  "7QM6pEJ9bxZHK50e1kww",
  "xiNW1zgeb2ITHGi6uQtg",
  "s8duZcGBbu0dxb4xEAGg",
]);

// The ID of the card immediately after the manipulated card
export const efficacyExperimentSubsequentCardID = "mI5Sf36iMrDKMpdFHdWU";

export function shouldNotifyForTestReviewSession(
  userRecord: UserRecord,
  evaluationTimestampMillis: number,
): boolean {
  if (!userRecord.efficacy2EnrollmentTimestamp) {
    return false;
  }

  if (userRecord.efficacy2TestReviewSessionState === "notified") {
    return true;
  }

  if (userRecord.efficacy2TestReviewSessionState === "started") {
    return false;
  }

  const efficacyCondition = getEnrolledConditionForUser(
    "efficacy2",
    userRecord,
  );
  if (efficacyCondition === null) {
    return false;
  } else if (efficacyCondition === "delay5Days") {
    if (!userRecord.efficacy2TestReviewSessionState) {
      return (
        evaluationTimestampMillis -
          userRecord.efficacy2EnrollmentTimestamp.toMillis() >=
        1000 * 60 * 60 * 24 * 4.25
      );
    } else if (userRecord.efficacy2TestReviewSessionState === "complete") {
      return (
        evaluationTimestampMillis -
          userRecord.efficacy2EnrollmentTimestamp.toMillis() >=
        1000 * 60 * 60 * 24 * 30.25
      );
    } else {
      return false;
    }
  } else {
    return (
      !userRecord.efficacy2TestReviewSessionState &&
      evaluationTimestampMillis -
        userRecord.efficacy2EnrollmentTimestamp.toMillis() >=
        1000 * 60 * 60 * 24 * 30.25
    );
  }
}
