import { useEffect, useState } from "react";

export function useScrollDependentState<State>(
  callback: (state: State | undefined) => State,
): State {
  const [state, setState] = useState(callback(undefined));
  useEffect(() => {
    function onScroll() {
      setState(callback);
    }
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [callback]);
  return state;
}
