import { CardIDsByEssay, CardStates } from "../db";
import { EssayID } from "../essays/essayMetadata";
import {
  schedulesToIntervalSequences,
  SpacedRepetitionSchedule,
} from "../spacedRepetition";
import typedKeys from "./typedKeys";
import { UserExperimentConditions } from "../experiments/types";
import { efficacyExperimentCardIDSet } from "../experiments/efficacy2";

export type EssayProgress = {
  [key: number]: EssayLevelProgress;
  total: EssayLevelProgress;
};
type EssayLevelProgress = {
  collectedCardCount: number;
  totalCardCount: number;
};

export type ProgressByEssay = {
  [key in EssayID]: EssayProgress;
};

// 95% collected at a level counts as completed.
const levelCompletionThreshold = 0.95;

export default function getProgressByEssay(
  cardStates: CardStates,
  cardIDsByEssay: CardIDsByEssay,
  schedule: SpacedRepetitionSchedule,
  relevantExperimentConditions: UserExperimentConditions,
): ProgressByEssay {
  const progressByEssay: Partial<ProgressByEssay> = {};
  const sequence = schedulesToIntervalSequences[schedule];

  const efficacyCondition = relevantExperimentConditions["efficacy2"] || null;

  for (const essayName of typedKeys(cardIDsByEssay)) {
    let totalCardCount: number;
    if (
      essayName === "qcvc" &&
      (efficacyCondition === "delay1Month" || efficacyCondition === "delay5Days")
    ) {
      // If they're participating in the efficacy experiment, we filter out any of the manipulated questions they haven't yet answered.
      totalCardCount = cardIDsByEssay[essayName].filter(
        cardID =>
          cardStates[cardID] || !efficacyExperimentCardIDSet.has(cardID),
      ).length;
    } else {
      totalCardCount = cardIDsByEssay[essayName].length;
    }

    const essayStats: ProgressByEssay[EssayID] = {
      total: {
        collectedCardCount: cardIDsByEssay[essayName].filter(
          cardID =>
            cardStates[cardID] &&
            typeof cardStates[cardID].bestInterval === "number",
        ).length,
        totalCardCount,
      },
    };
    sequence.forEach((entry, index) => {
      essayStats[index] = {
        collectedCardCount: cardIDsByEssay[essayName].filter(cardID => {
          if (!cardStates[cardID]) {
            return false;
          }
          const { bestInterval } = cardStates[cardID];
          if (typeof bestInterval === "number") {
            return bestInterval >= entry.interval;
          } else {
            return false;
          }
        }).length,
        totalCardCount:
          index === 0
            ? totalCardCount
            : essayStats[0].collectedCardCount === essayStats[0].totalCardCount
            ? Math.floor(totalCardCount * levelCompletionThreshold)
            : Math.max(
                Math.floor(totalCardCount * levelCompletionThreshold),
                essayStats[0].totalCardCount + 1,
              ),
      };
    });
    progressByEssay[essayName] = essayStats;
  }
  return progressByEssay as ProgressByEssay;
}
