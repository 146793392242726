import "./MobileNav.scss";

import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

import { ReactComponent as CloseGlyph } from "../assets/close.svg";
import { ReactComponent as LogoLockup } from "../assets/logo-lockup.svg";
import { ReactComponent as MenuGlyph } from "../assets/menu.svg";
import { UserState } from "../db";
import { TableOfContents, TableOfContentsNode } from "../document";
import { ProgressByEssay } from "../util/getProgressByEssay";
import Button from "./Button";
import EssayProgressIndicator from "./EssayProgressIndicator";
import { useScrollDependentState } from "./hooks/useScrollDependentState";
import useSignInRedirect from "./hooks/useSignInRedirect";
import IconButton from "./IconButton";
import InlineLaTeX from "./InlineLaTeX";
import {
  NavAndTableOfContentsLocation,
  NavEssayAndTableOfContentsLocation,
} from "./Nav";
import RadialProgressIndicator from "./RadialProgressIndicator";
import SiteMenu from "./SiteMenu";
import StudySessionRemainingIndicator from "./StudySessionRemainingIndicator";
import unreachableCaseError from "../util/unreachableCaseError";
import { SpacedRepetitionSchedule } from "../spacedRepetition";

interface MobileNavProps {
  userState: UserState;
  progressByEssay: ProgressByEssay | null;
  currentLocation: NavAndTableOfContentsLocation;
  essayProgressIndicatorRef: React.Ref<HTMLDivElement>;
  isShowingEssayProgressIndicatorOverlay: boolean;
  schedule: SpacedRepetitionSchedule;
}

export default React.memo(function MobileNav(props: MobileNavProps) {
  const {
    userState,
    progressByEssay,
    currentLocation,
    essayProgressIndicatorRef,
    isShowingEssayProgressIndicatorOverlay,
    schedule,
  } = props;

  const isScrolled = useIsScrolled();
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);

  const onMenuButtonClicked = () => setSideMenuOpen(true);
  const dismissSideMenu = () => setSideMenuOpen(false);

  useEffect(() => {
    if (isSideMenuOpen) {
      disableBodyScroll(document.body);
    } else {
      enableBodyScroll(document.body);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isSideMenuOpen]);

  // Dismiss the side menu when navigating to an anchor
  useEffect(() => {
    function onHashChange() {
      dismissSideMenu();
    }
    if (isSideMenuOpen) {
      window.addEventListener("hashchange", onHashChange);
      return () => window.removeEventListener("hashchange", onHashChange);
    }
  }, [isSideMenuOpen]);

  const menuButtonElement = (
    <div className="MenuButton">
      <IconButton onClick={onMenuButtonClicked}>
        <MenuGlyph />
      </IconButton>
    </div>
  );

  let contents: ReactNode;
  switch (currentLocation.locationType) {
    case "essay":
      const { currentTableOfContentsPath } = currentLocation;
      contents = (
        <>
          <div className="LeftItems">
            {menuButtonElement}
            {currentTableOfContentsPath && (
              <CurrentSectionLabel
                currentTableOfContentsPath={currentTableOfContentsPath}
                tableOfContents={currentLocation.tableOfContents}
              />
            )}
          </div>
          {!currentTableOfContentsPath && (
            <a href="/" className="Logo">
              <LogoLockup />
            </a>
          )}
          <div className="RightItems">
            <MobileEssayNavBarRightItems
              progressByEssay={progressByEssay}
              currentEssayLocation={currentLocation}
              userState={userState}
              essayProgressIndicatorRef={essayProgressIndicatorRef}
              isShowingEssayProgressIndicatorOverlay={
                isShowingEssayProgressIndicatorOverlay
              }
              schedule={schedule}
            />
          </div>
        </>
      );
      break;
    case "review":
      const { studySessionProgress } = currentLocation;
      contents = (
        <>
          <div className="LeftItems">{menuButtonElement}</div>
          <div className="RightItems">
            {studySessionProgress && (
              <StudySessionRemainingIndicator
                studySessionProgress={studySessionProgress}
              />
            )}
          </div>
        </>
      );
      break;
    case "frontPage":
      contents = (
        <>
          <div className="LeftItems">{menuButtonElement}</div>
          <LogoLockup className="Logo" />
          <div className="RightItems" />
        </>
      );
      break;
    default:
      throw unreachableCaseError(currentLocation);
  }

  return (
    <>
      <div className={`MobileNav ${isScrolled ? "Scrolled" : ""}`}>
        {contents}
      </div>
      {ReactDOM.createPortal(
        <CSSTransition
          in={isSideMenuOpen}
          timeout={1000}
          classNames="MobileSideMenu"
          mountOnEnter
          unmountOnExit
        >
          <div className="MobileSideMenu">
            <div className="Scrim" onClick={dismissSideMenu} />
            <div className="MobileSideMenuContents">
              <div className="CloseButton">
                <IconButton onClick={dismissSideMenu}>
                  <CloseGlyph />
                </IconButton>
              </div>
              <SiteMenu
                userState={userState}
                progressByEssay={progressByEssay}
                currentLocation={currentLocation}
                schedule={schedule}
              />
            </div>
          </div>
        </CSSTransition>,
        document.body,
      )}
    </>
  );
});

interface MobileEssayNavBarRightItemsProps {
  progressByEssay: ProgressByEssay | null;
  currentEssayLocation: NavEssayAndTableOfContentsLocation;
  userState: UserState;
  essayProgressIndicatorRef: React.Ref<HTMLDivElement>;
  isShowingEssayProgressIndicatorOverlay: boolean;
  schedule: SpacedRepetitionSchedule;
}

const MobileEssayNavBarRightItems = React.memo(
  function MobileEssayNavBarRightItems(
    props: MobileEssayNavBarRightItemsProps,
  ) {
    const {
      currentEssayLocation: { isActivelyReviewing },
      essayProgressIndicatorRef,
      isShowingEssayProgressIndicatorOverlay,
      schedule,
    } = props;

    const currentEssayProgress =
      props.progressByEssay &&
      props.progressByEssay[props.currentEssayLocation.essayID];
    const { onSignIn, RedirectToSignInIfNecessary } = useSignInRedirect(
      props.currentEssayLocation,
    );

    let element: ReactNode | null;
    if (
      currentEssayProgress &&
      (props.userState === "registered" ||
        currentEssayProgress.total.collectedCardCount > 0)
    ) {
      element = (
        <>
          <div className="Progress">
            <RadialProgressIndicator
              progressFraction={
                currentEssayProgress.total.collectedCardCount /
                currentEssayProgress.total.totalCardCount
              }
              displayValue={currentEssayProgress.total.collectedCardCount}
            />
          </div>
          <CSSTransition
            classNames="ProgressDrawer"
            timeout={1000}
            in={isActivelyReviewing || isShowingEssayProgressIndicatorOverlay}
            mountOnEnter
            unmountOnExit
          >
            <div
              className={`Progress ${
                isShowingEssayProgressIndicatorOverlay
                  ? "Expanded"
                  : "Collapsed"
              }`}
            >
              <EssayProgressIndicator
                progress={currentEssayProgress}
                form={
                  isShowingEssayProgressIndicatorOverlay
                    ? "exponential"
                    : "flat"
                }
                ref={essayProgressIndicatorRef}
                schedule={schedule}
              />
            </div>
          </CSSTransition>
        </>
      );
    } else if (props.userState === "anonymous") {
      element = (
        <Button variant="tertiary" size="regular" onActivate={onSignIn}>
          Sign in
        </Button>
      );
    } else {
      element = null;
    }

    return (
      <>
        <RedirectToSignInIfNecessary />
        {element}
      </>
    );
  },
);

const CurrentSectionLabel = React.memo(function CurrentSectionLabel(props: {
  currentTableOfContentsPath: TableOfContentsNode[];
  tableOfContents: TableOfContents;
}) {
  const { currentTableOfContentsPath } = props;

  const hasSubsection = currentTableOfContentsPath.length === 2;

  return (
    <div
      className={`CurrentSectionLabel ${hasSubsection ? "WithSubsection" : ""}`}
    >
      <p className="Section">
        <InlineLaTeX>{currentTableOfContentsPath[0].title}</InlineLaTeX>
      </p>
      {hasSubsection && (
        <p className="Subsection">
          <InlineLaTeX>{currentTableOfContentsPath[1].title}</InlineLaTeX>
        </p>
      )}
    </div>
  );
});

function useIsScrolled() {
  return useScrollDependentState(useCallback(() => window.scrollY > 10, []));
}
