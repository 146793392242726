import "./StudySessionRemainingIndicator.scss";

import React from "react";
import { SwitchTransition } from "react-transition-group";

import { ReactComponent as Check31 } from "../assets/check/31px.svg";
import FadeTransition from "./FadeTransition";
import RadialProgressIndicator from "./RadialProgressIndicator";

export interface StudySessionProgress {
  markedCardCount: number;
  totalCardCount: number;
}

export default function StudySessionRemainingIndicator(props: {
  studySessionProgress: StudySessionProgress;
}) {
  const { studySessionProgress } = props;

  let contents: React.ReactNode;
  const isComplete =
    studySessionProgress.markedCardCount ===
    studySessionProgress.totalCardCount;
  if (isComplete) {
    contents = (
      <div className="RemainingIndicator Complete">
        <span>Review complete!</span>
        <Check31 />
      </div>
    );
  } else {
    contents = (
      <div className="RemainingIndicator Incomplete">
        <span>Questions remaining:</span>
        <RadialProgressIndicator
          displayValue={
            studySessionProgress.totalCardCount -
            studySessionProgress.markedCardCount
          }
          progressFraction={
            studySessionProgress.markedCardCount /
            studySessionProgress.totalCardCount
          }
        />
      </div>
    );
  }
  return (
    <SwitchTransition>
      <FadeTransition key={isComplete.toString()}>{contents}</FadeTransition>
    </SwitchTransition>
  );
}
