import "./IconButton.scss";

import React from "react";

interface IconButtonProps {
  onClick: () => void;
  className?: string;
  children: React.ReactNode;
}

export default function IconButton(props: IconButtonProps) {
  return (
    <button
      className={`IconButton ${props.className || ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
