// TODO: box elements to prevent exposing HTMLElement types

import { CardData, CardType } from "./CardData";

export const getReviewSetElements = (document: Document) =>
  [
    ...document.getElementsByClassName("review_set"),
    ...document.getElementsByTagName("review-set"),
  ] as HTMLElement[];

export const getCardElements = (reviewSetElement: HTMLElement) =>
  ([...reviewSetElement.childNodes] as HTMLElement[]).filter(
    card => card.className === "card" || card.tagName === "CARD",
  );

export const getCardData = (
  cardElement: HTMLElement,
  nullCardID: string,
): CardData => {
  const getTextFromElement = (element: HTMLElement): string => {
    let text = element.textContent!.trim();

    text = text.replace(
      /(\$)([^$]+?)(\$)/gm,
      (match, prefix, contents, suffix) =>
        `${prefix}${contents.replace(/[\n\r]/g, "")}${suffix}`,
    );

    let displayMath: RegExpExecArray | null;
    const regexpDisplayMath = /\${2}([^$]*?)\${2}/gm;
    while ((displayMath = regexpDisplayMath.exec(text)) !== null) {
      text =
        text.substring(0, displayMath.index) +
        "\n\r\n\r" +
        text.substring(displayMath.index);
    }

    let image: RegExpExecArray | null;
    const regexpImages = /\s!(\[.*?]\(.+?\))/gm;
    while ((image = regexpImages.exec(text)) !== null) {
      text =
        text.substring(0, image.index + 1) +
        "\n\r\n\r" +
        text.substring(image.index + 1);
    }

    return text;
  };

  function getAdjustmentsFromElement(element: HTMLElement) {
    return element.getAttribute("data-adjustments") || null;
  }

  function getExplanationTextForAnswerElement(element: HTMLElement): string | null {
    const sibling = element.nextElementSibling as HTMLElement;
    if (sibling && sibling.tagName === "EXPLANATION") {
      return getTextFromElement(sibling);
    } else {
      return null;
    }
  }

  const cardType =
    cardElement.getAttribute("data-cardType") ||
    cardElement.getAttribute("type") ||
    "basic" as CardType;
  const cardID =
    cardElement.getAttribute("data-cardID") ||
    cardElement.getAttribute("id") ||
    nullCardID;
  const cardChildren = [...cardElement.childNodes.values()] as HTMLElement[];

  const questionPredicate = (n: HTMLElement): boolean =>
    n.className === "question" || n.tagName === "QUESTION";
  const answerPredicate = (n: HTMLElement): boolean =>
    n.className === "answer" || n.tagName === "ANSWER";

  const questions = cardChildren.filter(questionPredicate);
  const answers = cardChildren.filter(answerPredicate);

  switch (cardType) {
    case "basic":
      if (questions.length !== 1) {
        throw new Error(
          `Card should have exactly 1 question: ${cardElement.innerHTML}`,
        );
      }
      if (answers.length !== 1) {
        throw new Error(
          `Card should have exactly 1 answer: ${cardElement.innerHTML}`,
        );
      }
      return {
        cardType,
        cardID,
        question: getTextFromElement(questions[0]),
        answer: getTextFromElement(answers[0]),
        explanation: getExplanationTextForAnswerElement(answers[0]),
        questionAdjustments:
          getAdjustmentsFromElement(questions[0]) ||
          cardElement.getAttribute("data-questionAdjustments") ||
          null,
        answerAdjustments:
          getAdjustmentsFromElement(answers[0]) ||
          cardElement.getAttribute("data-answerAdjustments") ||
          null,
      };
    case "applicationPrompt":
      if (questions.length !== answers.length) {
        throw new Error(
          `Mismatched question/answer pairs in ${cardElement.innerHTML}`,
        );
      }
      if (questions.length === 0) {
        throw new Error(`No prompts found in ${cardElement.innerHTML}`);
      }
      return {
        cardType,
        cardID,
        prompts: questions.map((question, index) => ({
          question: getTextFromElement(question),
          answer: getTextFromElement(answers[index]),
          explanation: getExplanationTextForAnswerElement(answers[index]),
          questionAdjustments: getAdjustmentsFromElement(question),
          answerAdjustments: getAdjustmentsFromElement(answers[index]),
        })),
      };
    default:
      throw new Error(`Unknown card type: ${cardType}`);
  }
};

function getEssayContents(document: Document) {
  return document.getElementById("EssayContents")!;
}

export type TableOfContents = TableOfContentsSection[];

export interface TableOfContentsNode {
  title: string;
  id: string;
}

export interface TableOfContentsSection extends TableOfContentsNode {
  subsections: TableOfContentsSubsection[];
}

export interface TableOfContentsSubsection extends TableOfContentsNode {}

function getTableOfContentsNodeFromDOMNode(
  node: HTMLHeadingElement,
): TableOfContentsNode {
  if (!node.id) {
    console.error(
      `${node.outerHTML} has no id. We need an id for the table of contents.`,
    );
  }
  return {
    title: node.getAttribute("data-ToCTitle") || node.innerText,
    id: node.id,
  };
}

export function getTableOfContents(): TableOfContents {
  const contentsDOMNode = getEssayContents(document);
  const sectionDOMNodeList = contentsDOMNode.querySelectorAll("h1");
  const subsectionDOMNodeList = contentsDOMNode.querySelectorAll("h2");

  if (sectionDOMNodeList.length === 0 && subsectionDOMNodeList.length > 0) {
    // This essay has only h2s.
    return [...subsectionDOMNodeList].map(node => ({
      ...getTableOfContentsNodeFromDOMNode(node),
      subsections: [],
    }));
  } else {
    const subsectionIterator = subsectionDOMNodeList.values();
    let subsectionIteratorResult = subsectionIterator.next();

    return [...sectionDOMNodeList].map((node, sectionIndex) => {
      const nextNode: HTMLHeadingElement | null = sectionDOMNodeList.item(
        sectionIndex + 1,
      );

      const subsections: TableOfContentsSubsection[] = [];
      while (
        !subsectionIteratorResult.done &&
        (!nextNode ||
          subsectionIteratorResult.value.compareDocumentPosition(nextNode) &
            Node.DOCUMENT_POSITION_FOLLOWING)
      ) {
        const subsectionNode = subsectionIteratorResult.value;
        if (
          !(
            subsectionNode.compareDocumentPosition(node) &
            Node.DOCUMENT_POSITION_PRECEDING
          )
        ) {
          throw new Error(
            `Malformed essay: ${subsectionNode} precedes ${node}`,
          );
        }
        subsections.push(getTableOfContentsNodeFromDOMNode(subsectionNode));
        subsectionIteratorResult = subsectionIterator.next();
      }

      return {
        ...getTableOfContentsNodeFromDOMNode(node),
        subsections,
      };
    });
  }
}

export function getFullPathForTableOfContentsNode(
  node: TableOfContentsNode,
  tableOfContents: TableOfContents,
): TableOfContentsNode[] {
  // They can only be two deep for now, so we keep it easy.
  const parentNode = tableOfContents.find(section =>
    section.subsections.includes(node),
  );
  if (parentNode) {
    return [parentNode, node];
  } else {
    return [node];
  }
}
