import React, { useCallback, useState } from "react";

import { NavLocation } from "../Nav";
import unreachableCaseError from "../../util/unreachableCaseError";

export default function useSignInRedirect(
  currentLocation: NavLocation,
): {
  onSignIn: () => void;
  RedirectToSignInIfNecessary: React.ComponentType;
} {
  const [isPendingSignIn, setPendingSignIn] = useState(false);

  return {
    onSignIn: useCallback(() => setPendingSignIn(true), []),
    RedirectToSignInIfNecessary: useCallback(() => {
      // TODO: client-side nav is broken because of our chicanery. Fix, then make this client-side nav.
      /*return (
        <Redirect
          push
          to={{
            pathname: "/login",
            state: { originalPath: props.location.pathname },
          }}
        />
      );*/

      if (isPendingSignIn) {
        window.location.href = `/login?originalPath=${encodeURIComponent(
          pathForNavLocation(currentLocation),
        )}`;
      }
      return null;
    }, [currentLocation, isPendingSignIn]),
  };
}

function pathForNavLocation(currentLocation: NavLocation) {
  switch (currentLocation.locationType) {
    case "review":
      return "/review";
    case "essay":
      return `/${currentLocation.essayID}`;
    case "frontPage":
      return "/";
    default:
      throw unreachableCaseError(currentLocation);
  }
}
